@import (reference) '../../../commons.less';
@import (reference) '../../geoentity/GeoenetityListItem-commons.less';

.fuelContainer {
  display: flex;
  font-family: @fontFira;
  height: 127px;

  @media @desktop {
    width: 270px;
    height: 106px;
  }
}

.fuelContainer.withItineraryUniverse {
  height: 163px;

  @media @desktop {
    height: 142px;
  }
}

.fuelTagActionButton {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contentContainer {
  display: flex;
  gap: 8px;

  @media @desktop {
    gap: 16px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.fuelTitle {
  width: 270px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 2px;
  color: @colorGreyBlack;
}

.fuelTitle.withFuelPrice {
  width: 220px;

  @media @desktop {
    width: 190px;
  }
}

.gasPrice {
  width: 64px;
  height: 32px;
  border: 1px solid;
  border-radius: 4px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  margin-left: auto;
}

.gasName {
  display: flex;
  justify-content: center;
  padding: 0 4px;
  font-size: 12px;
  line-height: 16px;
  margin: -8px;
}

.gasText {
  background: inherit;
  background-clip: text;
  color: transparent;
  filter: invert(1) grayscale(1) contrast(9);
}

.gasPriceText {
  display: flex;
  justify-content: center;
  padding-top: 9px;
  font-size: 12px;
}

.fuelLastUpdate {
  font-size: 14px;
  line-height: 20px;
  color: @colorGreyDark;
}

.openingMessage {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.fuelButtonsSection {
  display: flex;
  align-items: center;
  margin-top: auto;
}
