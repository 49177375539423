@import (reference) '../../../commons.less';

.title {
  padding: 15px 0 5px 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.item {
  display: inline;

  .item + &::before {
    content: ' | ';
  }
}
.link {
  display: inline;
  font-size: 12px;

  &:active,
  &:hover {
    text-decoration: underline;
    .forceColor(@colorGreenDark);
  }
}
