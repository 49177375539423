@import (reference) '../../../commons.less';
@import (reference) '../../geoentity/GeoenetityListItem-commons.less';

.container {
  display: flex;
  font-family: @fontFira;
  min-height: 105px;

  @media @desktop {
    max-width: 235px;
  }

  &.withIllustration {
    margin-left: 86px;
  }
}

.illustration {
  position: absolute;
  width: @illustrationWidth;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: @colorGreyLighter;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 2px;
  color: @colorGreyBlack;
}

.address {
  font-size: 13px;
  line-height: 20px;
  color: @colorGreyDark;
  padding: 10px 0 14px 0;
  margin: 0;
}

.buttonsSection {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.addStepButton {
  display: flex;
  margin-top: 8px;
}
