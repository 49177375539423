@import (reference) '../../../commons.less';

.address {
  font-family: @fontFira;
  color: @colorGreyBlack;
  margin-bottom: 12px;
}

.street {
  font-size: 14px;
  font-weight: 500;
}

.city {
  font-size: 14px;
}

.buttonWrapper {
  font-family: @fontFira;
  margin-bottom: 15px;
  text-align: center;
}

.linkPoiItemText {
  height: 17px;
  border-bottom: 1px solid @colorGreenMedium;
}

.linkPoiItem {
  font-size: 13px;
  color: @colorGreenMedium;
  margin-bottom: 5px;
  display: block;
  cursor: pointer;
  font-family: @fontFira;

  & svg {
    height: 20px;
    width: 30px;
    fill: @colorGreenMedium;
    vertical-align: top;
  }

  &:hover {
    color: @colorGreyDark;

    .linkPoiItemText {
      border-color: @colorGreyDark;
    }
    svg {
      fill: @colorGreyDark;
    }
  }
}
