@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('./fira-sans-v16-latin-regular.woff2') format('woff2'),
    url('./fira-sans-v16-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('./fira-sans-v16-latin-500.woff2') format('woff2'),
    url('./fira-sans-v16-latin-500.woff') format('woff');
}
