@import url('./reset.less');

header,
footer,
main,
dialog,
section,
nav,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
p,
ul,
ol,
li,
dl,
dt,
dd,
input,
button {
  box-sizing: border-box;
}

input,
button,
a {
  outline: 0;
}

ul,
h1 {
  all: unset;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

/* ==========================================================================
   Forms
   ========================================================================== */

body {
  -webkit-print-color-adjust: exact;
}
