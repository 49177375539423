.root {
  position: relative;
  width: 128px;
  height: 80px;
  border-radius: 5px;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
  }
}

.noPano {
  padding: 5px 4px 8px;
  text-align: center;
  line-height: 16px;
}
