@mobile: ~' screen and (max-width: 999px)';
@desktop: ~' screen and (min-width: 1000px)';
@landscape: ~' screen and (orientation: landscape)';
@print: ~' print';
@hover: ~' (hover: hover) and (pointer: fine)';

@fontFira: 'Fira Sans';

@colorGreenMedium: #03ac46;
@colorGreenDark: #027831;
@colorBlack90: #1a1a1a;
@colorBlack30: #b3b3b3;
@colorGreyLighter: #f0f0f0;
@colorGreyLight: #dcdcdc;
@colorGreyLightAlternative: #c4c4c4;
@colorGreyMedium: #888;
@colorGreyDark: #666;
@colorGreyBlack: #333;
@colorBlackLight: #404040;
@colorBlueMedium: #2072e7;
@colorBlueMediumShadow: darken(@colorBlueMedium, 10%);
@colorBlueDark: #1650a2;
@colorOrangeMedium: #ff9000;
@colorOrangeDark: #ff7600;
@colorGradientGreenToBlue: linear-gradient(to right, #5dc95f, #39bdc9);
@colorCategoryHotel: #0056a4;
@colorCategoryPratique: #fead00;

@sectionBorderWidth: 4px;
@sectionBorderDetails: ~'solid rgba(0, 0, 0, .12)';
@sectionBorder: @sectionBorderWidth @sectionBorderDetails;

@mainBoxShadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
@mainButtonBorder: 1px solid rgba(102, 102, 102, 0.5);

@mainBlockBorder: 1px solid @colorGreyLight;
@footerBlockBorder: 2px @sectionBorderDetails;

@subHeaderHeight: 46px;
@desktopMainWidth: 415px;
@desktopMainDetailWidth: 419px;
@desktopTopBarHeight: 70px;
@desktopLandingColWidth: 510px;
@desktopItineraryLandingColWidth: 944px;

@footerDefaultHeight: 225px;
@footerHeightWithoutTopSearchLinks: 173px;

@headerSearchHeightMobile: 70px;
@headerSearchHeightDesktop: 48px;
@headerMenuCategories: 70px;

@miniMapHeight: 250px;
@fullHeaderHeightDesktop: @desktopTopBarHeight + @headerSearchHeightDesktop;
@subHeaderTop_desktop: @fullHeaderHeightDesktop + @subHeaderHeight + 18px;
@miniMapBottom: @headerSearchHeightMobile + @subHeaderHeight + @miniMapHeight;

@headerDetailHeight: 124px;
@headerDetailDesktopTop: 90px;
@headerDetailDesktopHeight: 70px;
@mainDetailDesktopTop: @headerDetailDesktopTop + @headerDetailDesktopHeight;
@detailComponentsLeftDesktop: 435px;
@footerDetailHeight: 90px;
@footerDetailTotalHeight: 100px;

@headerTransitionTime: 0.5s;

@z_optionDialogValidateArea: 1;
@z_routeHighlighter: 1;
@z_mapFullScreenButton: 1;
@z_mapWeather: 1;
@z_optionDialogIncentive: 2;
@z_mobileAdBanner: 2;
@z_geoentityCardButtons: 3;
@z_menuPE: 10;
@z_mapGeolocationMarker: 10;
@z_marker_address: 20;
@z_scroll_list_arrow: 20;
@z_marker_active: 30;
@z_nps_survey: 40;
@z_adBanner: 40;
@z_vdeBanner: 49;
@z_homeBanner: 50;
@z_mapTools: 50;
@z_popup: 51;
@z_mapTooSlow: 52;
@z_mapHdTooltip: 52;
@z_mapUpdate: 53;
@z_main: 70;
@z_sub_header: 100;
@z_geoentityHeader: 100;
@z_geoentityFooter: 100;
@z_searchDialog: 110;
@z_menuGE: 110;
@z_push_notification: 120;
@z_dynamicMapShadow: 120;
@z_header: 120;
@z_settings_dialog: 130;
@z_close_popin_button: 140;
@z_userAccount: 145;
@z_cmp: 150;
@z_outdoor: 160;
@z_outdoor_close_button: @z_outdoor + 1;
@z_modal: 180;
@z_alert_banner: 200;
// Modal.js uses zIndex 180 in JS

html,
button,
input,
select,
textarea,
dialog,
a,
.linkc,
.linkcu {
  color: @colorGreyBlack;
  font-family: @fontFira;
}

.vertical-align () {
  top: 50%;
  transform: translateY(-50%);
}

.horizontal-align () {
  left: 50%;
  transform: translateX(-50%);
}

.vertical-horizontal-align () {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.borderRadius (@fieldHeight) {
  height: @fieldHeight;
  border-radius: (@fieldHeight / 2);
  border: none;
}

.headerForm () {
  position: absolute;
  left: 15px;
  right: 15px;
  z-index: 2;

  :global(.withBackButton) & {
    left: 35px;

    @media @mobile {
      left: 45px;
    }
  }
}

.footer-at-bottom() {
  @media @desktop {
    min-height: calc(100% - @footerDefaultHeight);
  }
}

.hide-text () {
  overflow: hidden;
  text-indent: -9999px;
}

.image-background (@width, @height, @url) {
  width: @width;
  height: @height;
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-image: @url;
}

.ellipsis () {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.clearfix () {
  &::before {
    content: ' ';
    display: table;
  }
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

.forceColor (@color) {
  color: @color;
  fill: @color;
  -webkit-text-fill-color: @color; // hack for ios link color
}

.drop-shadow() {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

/******** Text Styles *********/

.fontSize32 () {
  font-size: 32px;
  line-height: 38px;
}

.fontSize24 () {
  font-size: 24px;
  line-height: 32px;
}

.fontSize18 () {
  font-size: 18px;
  line-height: 24px;
}

.fontSize16 () {
  font-size: 16px;
  line-height: 20px;
}

:global(.textStyleHeader1) {
  font-weight: 500;
  .fontSize18();

  @media @desktop {
    .fontSize32();
  }
}

:global(.textStyleHeader2) {
  font-weight: 500;
  .fontSize16();

  @media @desktop {
    .fontSize24();
  }
}

/******** Inputs *********/

.roundInput {
  display: block;
  box-sizing: border-box;
  border: 1px solid #999;
  height: 36px;
  border-radius: 18px;
  padding: 0 20px;
  line-height: 36px;
  font-size: 17px;
  text-align: left;
  cursor: text;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/******** Colors *********/

.invertColor {
  background: inherit;
  background-clip: text;
  color: transparent;
  filter: invert(1) grayscale(1) contrast(9);
}

/******** Buttons *********/

.button-style {
  padding: 4px 8px;
  border: 1px solid @colorGreyLight;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  white-space: nowrap;
  background: #fff;
  text-decoration: none;
  cursor: pointer;
}

.rounded-button-style(@primaryColor, @secondaryColor) {
  .button-style();

  .forceColor(@secondaryColor);

  background-color: @primaryColor;
  border-radius: 50px;

  &:active,
  &:hover {
    text-decoration: none;
    background-color: @secondaryColor;
    border: 1px @primaryColor solid;
    .forceColor(@primaryColor);
  }
}

.round-button-style {
  .button-style();

  border-radius: 24px;
}

.green-full-button-hover-style {
  background: @colorGreenMedium;
  border-color: @colorGreenMedium;
  color: white;
}

.green-empty-button-style {
  .button-style;

  border-color: @colorGreenDark;
  color: @colorGreenDark;

  @media @desktop {
    &:hover {
      .green-full-button-hover-style;
    }
  }
}

.green-button-style () {
  .button-style;
  .big-button-style;
  .green-empty-button-style;
}

.grey-empty-button-style {
  .button-style;

  border-color: @colorGreyDark;
  color: @colorGreyDark;
  &:hover {
    .green-full-button-hover-style;
  }
}

.grey-button-style () {
  .button-style;
  .big-button-style;
  .grey-empty-button-style;
}

.blue-full-button-style {
  .button-style;
  .big-button-style;

  background: @colorBlueMedium;
  border-color: @colorBlueMedium;
  color: white;
  fill: white;
  &:hover {
    .blue-full-button-hover-style;
  }
}

.blue-full-button-hover-style {
  background: white;
  color: @colorBlueMedium;
  fill: @colorBlueMedium;
}

.big-button-style {
  border-radius: 14px;
  font-size: 14px;
  line-height: 14px;
}

/** Arrows mixins definitions */

.arrow (@top, @left, @size, @position) {
  &::after {
    border: solid transparent;
    content: ' ';
    display: inline-block;
    position: @position;
    pointer-events: none;
    top: @top;
    left: @left;
    border-width: @size;
    margin-left: -@size;
  }
}

.arrow-up (@backgroundColor, @top, @left, @size, @position: absolute) {
  .arrow(@top, @left, @size, @position);
  &::after {
    border-bottom: @size solid @backgroundColor;
  }
}
.arrow-down (@backgroundColor, @top, @left, @size, @position: absolute) {
  .arrow(@top, @left, @size, @position);
  &::after {
    border-top: @size solid @backgroundColor;
  }
}

/** EOF Arrows mixins definitions */

:global(.ssrImageGrey) {
  filter: brightness(0) saturate(100%) invert(43%) sepia(0%) saturate(1582%) hue-rotate(317deg) brightness(88%)
    contrast(80%);
}
