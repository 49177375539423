@import (reference) '../../../commons.less';

.rectangle-button () {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 3px 11px;
  border: 1px solid transparent;
  border-radius: 14px;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  letter-spacing: 0.7px;
  text-decoration: none;
  cursor: pointer;

  &::first-letter {
    text-transform: capitalize;
  }
}

.rectangle-button-small () {
  padding: 5px 8px 3px;
  font-size: 10px;
  border-radius: 11px;
  line-height: normal;
}

.rectangle-button-big () {
  padding: 9px 19px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
}

.rectangle-button-colors-full (@primaryColor, @secondaryColor) {
  background-color: @primaryColor;
  border-color: @primaryColor;
  .forceColor(@secondaryColor);
}

.rectangle-button-colors-full (@primaryColor, @secondaryColor, @ternaryColor) {
  background-color: @primaryColor;
  border-color: @ternaryColor;
  .forceColor(@secondaryColor);
}

.rectangle-button-colors-empty (@primaryColor, @secondaryColor) {
  background-color: @secondaryColor;
  border-color: @primaryColor;
  .forceColor(@primaryColor);
}

.rectangle-button-colors-full-with-hover (@primaryColor, @secondaryColor) {
  .rectangle-button-colors-full(@primaryColor, @secondaryColor);

  &:hover {
    .rectangle-button-colors-full(darken(@primaryColor, 10%), @secondaryColor);
  }

  &:active {
    .rectangle-button-colors-full(@primaryColor, @secondaryColor, darken(@primaryColor, 10%));
  }
}

.rectangle-button-colors-full-with-empty-hover (@primaryColor, @secondaryColor) {
  .rectangle-button-colors-full(@primaryColor, @secondaryColor);

  &:hover,
  &:active {
    .rectangle-button-colors-empty(@primaryColor, @secondaryColor);
  }
}

.rectangle-button-colors-empty-with-hover (@primaryColor, @secondaryColor) {
  .rectangle-button-colors-empty(@primaryColor, @secondaryColor);

  &:hover {
    .rectangle-button-colors-full(@primaryColor, @secondaryColor);
  }

  &:active {
    .rectangle-button-colors-full(darken(@primaryColor, 10%), @secondaryColor);
  }
}

.icon {
  width: 16px;
  height: 16px;
}

.img {
  width: 22px;
  height: 22px;
}

.button {
  .rectangle-button();

  &.icon_right {
    flex-direction: row-reverse;

    .icon,
    .img {
      margin-left: 4px;
    }
  }

  &.icon_left {
    .icon,
    .img {
      margin-right: 5px;
    }
  }

  &.withIcon_chevron {
    padding-left: 5px;
  }

  &.onlyIcon {
    padding: 5px;

    .icon {
      margin: 0;
    }
  }

  &.small {
    .rectangle-button-small();

    .icon {
      width: 14px;
      height: 14px;
      left: 7px;
    }
  }

  &.big {
    .rectangle-button-big();

    &.onlyIcon {
      padding: 9px;
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}

.button + .button {
  margin-left: 10px;
}

.green {
  .rectangle-button-colors-full-with-hover(@colorGreenMedium, white);
}

.green_empty {
  .rectangle-button-colors-empty-with-hover(@colorGreenMedium, white);
}

.pinegreen {
  .rectangle-button-colors-full-with-empty-hover(@colorGreenMedium, white);
}

.orange {
  .rectangle-button-colors-full-with-hover(@colorOrangeDark, white);
}

.orange_empty {
  .rectangle-button-colors-empty-with-hover(@colorOrangeDark, white);
}

.blue {
  .rectangle-button-colors-full-with-hover(@colorBlueMedium, white);
}

.blue_empty {
  .rectangle-button-colors-empty-with-hover(@colorBlueMedium, white);
}

.darkblue {
  .rectangle-button-colors-full-with-hover(@colorBlueDark, white);
}

.darkblue_empty {
  .rectangle-button-colors-empty-with-hover(@colorBlueDark, white);
}

.grey_empty {
  .rectangle-button-colors-empty(@colorGreyDark, white);

  &:active,
  &:hover {
    .rectangle-button-colors-full(@colorGreyLighter, @colorGreyBlack);

    border: 1px solid @colorGreyMedium;
  }
}
