@import url('./reset.mappy.less');
@import url('./commons.less');
@import url('./domain/fonts/fonts.less');

:global(.icon) {
  background: none;
  border: 0;
  padding: 0;
  width: 22px;
  height: 22px;

  svg {
    width: 100%;
    height: 100%;
  }
}

/* stylelint-disable-next-line selector-not-notation */
body:not(:global(.ssr)):not(:global(.scrollable)) {
  @media @desktop {
    overflow: hidden;
  }
}

body:not(:global(.ssr)):global(.unscrollable) {
  @media @mobile {
    overflow: hidden;
  }
}

@media @desktop {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    border: 1px solid #dcdcdc;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #c2c2c2;
    border: 1px solid white;
  }
}
