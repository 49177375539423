.ssrImg {
  max-width: 36px;
  max-height: 36px;
}

.ssrImgGrey {
  filter: brightness(0) saturate(100%) invert(40%) sepia(0%) saturate(1%) hue-rotate(341deg) brightness(97%)
    contrast(93%);
}

.ssrImgWhite {
  filter: invert(1); /* white by inverting black color */
}
